import { useEffect, useState } from "react";

import { IS_APP } from "@/__main__/constants.mjs";
import { ACTIVE_GAMES, GAME_SHORT_NAMES } from "@/app/constants.mjs";
import adsRefs from "@/feature-ads/refs.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const gameHomeRoutes = ACTIVE_GAMES.map(
  (gameSymbol) => `/${GAME_SHORT_NAMES[gameSymbol]}`,
);

const pathReg = IS_APP
  ? /\/(profile|in-game|match|video-ad-test)/
  : new RegExp(`^(${gameHomeRoutes.join("|")}|/lol/champions(/.*)?)$`);

const useShouldShowAdVideoContainer = () => {
  const { showIngameVideo } = useSnapshot(adsRefs);
  const route = useRoute((a, b) => a?.currentPath === b?.currentPath, {
    isGlobal: true,
  });

  const [show, setShow] = useState(false);
  useEffect(() => {
    //  As long as the ad node show, it will remain displayed
    if (show) return;
    if (pathReg.test(route.currentPath)) {
      setShow(true);
    }
  }, [route?.currentPath, showIngameVideo, show]);

  return show;
};

export default useShouldShowAdVideoContainer;
